import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4-4-4-4\\@85% 1RM`}</p>
    <p>{`&`}</p>
    <p>{`Glute Ham Raise 8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`1000M Row for time.`}</p>
    <p>{`Rest 2 minutes then: Death by back squat.`}</p>
    <p>{`-1 back squat the first minute, 2 the second minute, etc.`}</p>
    <p>{`-Men use their bodyweight, women use 75% bodyweight.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free wod so bring a friend! 8:00 & 9:00am at
The Ville and 10:00 & 11:00am at East.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      